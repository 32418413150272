import React, { forwardRef } from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import classNames from 'classnames'

import * as classes from './input.module.scss'

interface Props extends FormControlProps {
  className?: string
  required?: boolean
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }, ref) => (
    <Form.Control
      className={classNames(classes.input, className)}
      {...props}
      ref={ref}
    />
  )
)
