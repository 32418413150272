import React from 'react'
import { Form, FormLabel } from 'react-bootstrap'
import { Link } from 'gatsby'
import classNames from 'classnames'

import {
  Blanket,
  BlanketTitle,
  BlanketSubtitle,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Button } from '../../components/Button'
import { Container } from '../../components/Container'
import { Input } from '../../components/Input'
import { SEO } from '../../components/Seo'

import * as classes from './newsletter.module.scss'

export default function NewsletterPage() {
  return (
    <Blanket className={classes.newsletterSection}>
      <SEO title={'Newsletter'} />

      <BlanketBackground>
        <Container size={'small'}>
          <BlanketContent>
            <iframe
              src='https://go.pallacanestroreggiana.it/l/1043883/2023-11-16/dfpknc'
              width='100%'
              height='1000'
              type='text/html'
              frameBorder='0'
              allowTransparency
              style={{ border: 0 }}
            ></iframe>
          </BlanketContent>
        </Container>
      </BlanketBackground>
    </Blanket>
  )
}

interface State {
  name: string
  email: string
  privacyChecked: boolean
  validated: boolean
}

class NewsLetter__legacy__ extends React.Component<{}, State> {
  state = {
    name: '',
    email: '',
    privacyChecked: false,
    validated: false,
  }

  handleFieldChange =
    (field: 'name' | 'email') => (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
      }))
    }

  handlePrivacyCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      privacyChecked: event.currentTarget.checked,
    })
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { name, email } = this.state
    const form = event.currentTarget

    if (!form.checkValidity()) {
      event.stopPropagation()
    }

    this.setState({
      validated: true,
    })

    return

    try {
      const res = await fetch(
        `https://${process.env.REACT_APP_MAIL_CHIMP_REGION}.api.mailchimp.com/3.0/lists/${process.env.REACT_APP_MAIL_CHIMP_LIST_ID}/members`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Basic ${Buffer.from(
              `apikey:${process.env.REACT_APP_MAIL_CHIMP_API_KEY}`
            ).toString('base64')}`,
          },
          body: JSON.stringify({
            email_address: email,
            status: 'subscribed',
          }),
        }
      )

      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  render() {
    const { name, email, privacyChecked, validated } = this.state

    return (
      <Blanket className={classes.newsletterSection}>
        <SEO title={'Newsletter'} />

        <BlanketBackground>
          <Container size={'small'}>
            <BlanketTitle>Newsletter</BlanketTitle>

            <BlanketSubtitle>
              Iscriviti per rimanere sempre aggiornato
            </BlanketSubtitle>

            <BlanketContent>
              <p>
                Questo strumento rappresenta il modo piu semplice e veloce per
                restare aggiornati sulle novità relative alla squadra: mercato,
                spostamenti gare, iniziative e molto altro!
              </p>

              <p className={classes.spacer}>
                Iscriversi è semplicissimo. Vi aspettiamo!
              </p>

              <Form
                action={
                  'https://pallacanestroreggiana.us9.list-manage.com/subscribe/post'
                }
                method={'post'}
                noValidate
                validated={validated}
                target={'_blank'}
              >
                <input
                  type={'hidden'}
                  name={'u'}
                  value={'82763a3fdb86083972f4e72e3'}
                />
                <input type={'hidden'} name={'id'} value={'8a52fc0d49'} />

                {/* people should not fill these in and expect good things */}
                <div
                  className={classes.fieldShift}
                  aria-label={'Please leave the following three fields empty'}
                >
                  <label htmlFor={'b_name'}>Name: </label>
                  <input
                    type={'text'}
                    name={'b_name'}
                    tabIndex={-1}
                    value={''}
                    placeholder={'Freddie'}
                    id={'b_name'}
                  />

                  <label htmlFor={'b_email'}>Email: </label>
                  <input
                    type={'email'}
                    name={'b_email'}
                    tabIndex={-1}
                    value={''}
                    placeholder={'youremail@gmail.com'}
                    id={'b_email'}
                  />

                  <label htmlFor={'b_comment'}>Comment: </label>
                  <textarea
                    name={'b_comment'}
                    tabIndex={-1}
                    placeholder={'Please comment'}
                    id={'b_comment'}
                  />
                </div>

                <div id={'mergeTable'}>
                  <div
                    className='mergeRow dojoDndItem mergeRow-email'
                    id='mergeRow-0'
                  >
                    <FormLabel htmlFor='MERGE0'>
                      E-mail <span className='req asterisk'>*</span>
                    </FormLabel>
                    <div className='field-group'>
                      <Input
                        type='email'
                        name='MERGE0'
                        id='MERGE0'
                        className={classes.input}
                      />
                    </div>
                  </div>

                  <div
                    className='mergeRow dojoDndItem mergeRow-text'
                    id='mergeRow-1'
                  >
                    <FormLabel htmlFor='MERGE1'>Nome</FormLabel>
                    <div className='field-group'>
                      <Input
                        type='text'
                        name='MERGE1'
                        id='MERGE1'
                        className={classes.input}
                      />
                    </div>
                  </div>

                  <div
                    className='mergeRow dojoDndItem mergeRow-text'
                    id='mergeRow-2'
                  >
                    <FormLabel htmlFor='MERGE2'>Cognome</FormLabel>
                    <div className='field-group'>
                      <Input
                        type='text'
                        name='MERGE2'
                        id='MERGE2'
                        className={classes.input}
                      />
                    </div>
                  </div>

                  <div
                    className='mergeRow dojoDndItem mergeRow-radio'
                    id='mergeRow-3'
                  >
                    <label htmlFor='MERGE3'>
                      Privacy <span className='req asterisk'>*</span>
                    </label>
                    <div className='field-group'>
                      <div
                        className='interestgroup_field radio-group'
                        id='MERGE3'
                      >
                        <Form.Check
                          type='radio'
                          name='MERGE3'
                          id='MERGE3-0'
                          value='Selezionando questa casella autorizzo il trattamento dei dati'
                          className='av-radio'
                          label={
                            'Selezionando questa casella autorizzo il trattamento dei dati'
                          }
                        />

                        <Form.Check
                          type='radio'
                          name='MERGE3'
                          id='MERGE3-1'
                          value='Non autorizzo'
                          className='av-radio'
                          label={'Non autorizzo'}
                        />
                      </div>
                    </div>

                    <Link
                      to={'/privacy-policy'}
                      className={classNames(
                        classes.privacyLink,
                        classes.labelBold
                      )}
                    >
                      Informativa sulla privacy
                    </Link>
                  </div>
                </div>

                <div className={classes.buttonContainer}>
                  <Button type={'submit'} className={classes.button}>
                    Iscriviti
                  </Button>
                </div>

                <input
                  type='hidden'
                  name='ht'
                  value='6bbf66af70f1631cd10292f10e9dd0aa0e26a699:MTU4MDE0MTYzMi4yMjcy'
                />
                <input type='hidden' name='mc_signupsource' value='hosted' />
              </Form>
            </BlanketContent>
          </Container>
        </BlanketBackground>
      </Blanket>
    )
  }
}
